import React, { useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col, Card, Accordion } from "react-bootstrap"
import styled from "styled-components"
import USA from "./USAWithLabel"
import Canada from "./Canada"
import Mexico from "./Mexico"
import { RadioSVGMap } from "react-svg-map"
import "react-svg-map/lib/index.css"

import GlobalContext from "../../contexts/GlobalContext"

import { itemsByLang } from "../../utils/helperFn"

import { Box } from "../Core"
import { theme } from "../../utils"

const countryMaps = [
  {
    name: "canada",
    map: Canada,
  },
  {
    name: "united-states",
    map: USA,
  },
  {
    name: "mexico",
    map: Mexico,
  },
]

const CardStyled = styled(Card)`
  .card-header {
    cursor: pointer;
    font-weight: bold;
  }
  ul {
    margin-bottom: 0;
  }

  p,
  .p {
    font-size: 1rem !important;
  }

  &.accordion-card-map,
  &.accordion-card-map > .card-header {
    background: ${({ theme }) => theme.colors.lightShade} !important;
    transition: all 0.2s ease-out;
    border-color: ${({ theme }) => theme.colors.border} !important;
  }

  &.accordion-card-map > .card-header {
    user-select: none;
  }

  &.accordion-card-map > .card-header {
    font-weight: 600;
  }

  .card-body,
  &.accordion-card-map:not(.active) > .card-header:hover {
    color: ${({ theme }) => theme.colors.dark} !important;
    background-color: #ffffff !important;
  }

  &.accordion-card-map.active > .card-header {
    color: #fff !important;
    background: ${({ theme, colorBrand }) =>
      colorBrand ? colorBrand : theme.colors.primary} !important;
    border-color: ${({ theme }) => theme.colors.border} !important;
  }

  a.subcat-link {
    transition: all 0.15s ease-out;
    user-select: none;
    &:hover {
      color: ${({ theme, colorHover }) =>
        colorHover ? colorHover : theme.colors.primary} !important;
      text-decoration: none !important;
      outline: none !important;
      font-weight: 600;
    }
    &.active {
      text-decoration: none !important;
      outline: none !important;
      color: ${({ theme, colorActive }) =>
        colorActive ? colorActive : theme.colors.primary} !important;
      font-weight: 600;
    }
  }
`

const CloseWrapper = styled.div`
  cursor: pointer;
  top: 1rem;
  right: 1.5rem;
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  z-index: 10;
  color: ${theme.dark};
  @media only screen and (min-width: ${theme.breakpoints.md}px) {
    right: 3rem;
  }
`

const CloseButton = props => (
  <CloseWrapper {...props}>
    <svg
      role="img"
      viewBox="0 0 24 24"
      css={`
        fill: #000;
        vertical-align: middle;
        height: 1rem;
        width: 1rem;
      `}
    >
      <path
        d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
        fill="#000"
      ></path>
    </svg>
  </CloseWrapper>
)

const MapSVG = ({ continent }) => {
  const gContext = useContext(GlobalContext)

  const { allPrismicCountry, allPrismicState } = useStaticQuery(graphql`
    query {
      allPrismicCountry(sort: { fields: data___order, order: ASC }) {
        nodes {
          lang
          uid
          data {
            title {
              text
            }
            show_map
            continent {
              uid
            }
            address_1 {
              html
            }
            address_2 {
              html
            }
            address_3 {
              html
            }
          }
        }
      }
      allPrismicState {
        nodes {
          lang
          data {
            name {
              text
            }
            id {
              text
            }
            country {
              uid
            }
            continent {
              uid
            }
            address_1 {
              html
            }
            address_2 {
              html
            }
            address_3 {
              html
            }
          }
        }
      }
    }
  `)

  const allCountriesTotal = allPrismicCountry.nodes.filter(
    cnt => cnt.data.continent.uid === continent.uid
  )
  const allCountries = itemsByLang(allCountriesTotal, gContext.currentLang)

  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayAddressArr, setOverlayAddressArr] = useState([])

  const handleStateSelect = (el, currentCountry) => {
    const locations = document.getElementsByClassName("svg-map__location")
    for (let i = 0; i < locations.length; i++) {
      locations[i].removeAttribute("aria-checked")
    }
    el.setAttribute("aria-checked", "true")

    const elStateId = el.getAttribute("id")

    let arr = []
    let c = 0

    allPrismicState.nodes.map(({ data }) => {
      // DOM element matches with Prismic state and not counted before
      if (
        data.id.text === elStateId &&
        data.country.uid === currentCountry.uid &&
        c === 0
      ) {
        arr.push(data.address_1)
        arr.push(data.address_2)
        arr.push(data.address_3)
        c = 10 //change counter value
      }
    })

    //Item is not listed in Prismic or Listed but no data in address_1 field and it has country defined
    if (c === 0 || (arr[0].html === "" && currentCountry)) {
      arr = []
      arr.push(currentCountry.data.address_1)
      arr.push(currentCountry.data.address_2)
      arr.push(currentCountry.data.address_3)
    }
    setOverlayAddressArr(arr)

    setShowOverlay(true)
  }

  const [activeCntKey, setActiveCntKey] = useState(
    allCountries.length > 0 ? allCountries[0].uid : null
  )

  const handleAccordionChange = country => {
    if (activeCntKey !== country.uid) {
      setActiveCntKey(country.uid)

      //Deactivate previously selected state and remove address & hide overlay
      const locations = document.getElementsByClassName("svg-map__location")
      for (let i = 0; i < locations.length; i++) {
        locations[i].removeAttribute("aria-checked")
      }
      setOverlayAddressArr([])
      setShowOverlay(false)
    } else {
      setActiveCntKey(null)
    }
  }

  return (
    <Box>
      <Container fluid>
        <Accordion activeKey={activeCntKey}>
          {allCountries.map(cnt => {
            const cntAddressArr = []
            cntAddressArr.push(cnt.data.address_1)
            cntAddressArr.push(cnt.data.address_2)
            cntAddressArr.push(cnt.data.address_3)
            const countryMap = countryMaps.filter(c => c.name === cnt.uid)[0]

            return (
              <CardStyled
                key={cnt.uid}
                className={`accordion-card-map ${
                  activeCntKey === cnt.uid ? "active" : ""
                }`}
              >
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={cnt.uid}
                  onClick={() => handleAccordionChange(cnt)}
                >
                  {cnt.data.title.text}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={cnt.uid}>
                  <Card.Body>
                    {cnt.data.show_map && countryMap ? (
                      <Box
                        className="position-relative"
                        css={`
                          .svg-map {
                            max-height: 500px !important;
                          }
                        `}
                      >
                        <RadioSVGMap
                          map={countryMap.map}
                          onLocationFocus={props =>
                            handleStateSelect(props.currentTarget, cnt)
                          }
                        ></RadioSVGMap>
                        <Box
                          bg="rgba(255,255,255,0.9)"
                          p={5}
                          className={`position-absolute ${
                            showOverlay ? "active" : ""
                          }`}
                          css={`
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            visibility: hidden;

                            &.active {
                              visibility: visible;
                              opacity: 1;
                            }
                          `}
                        >
                          <CloseButton onClick={() => setShowOverlay(false)} />
                          <Box
                            css={`
                              overflow-y: auto;
                              height: 100%;
                            `}
                          >
                            {overlayAddressArr.length > 0 &&
                              overlayAddressArr.map(
                                (address, i) =>
                                  address.html !== "" && (
                                    <Box
                                      dangerouslySetInnerHTML={{
                                        __html: address.html,
                                      }}
                                      key={i}
                                    ></Box>
                                  )
                              )}
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        css={`
                          overflow-y: auto;
                          overflow-x: hidden;
                          height: 100%;
                          width: 100%;
                        `}
                      >
                        <Row>
                          {cntAddressArr.length > 0 &&
                            cntAddressArr.map(
                              (address, i) =>
                                address.html !== "" && (
                                  <Col lg="4">
                                    <Box
                                      dangerouslySetInnerHTML={{
                                        __html: address.html,
                                      }}
                                      key={i}
                                    ></Box>
                                  </Col>
                                )
                            )}
                        </Row>
                      </Box>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </CardStyled>
            )
          })}
        </Accordion>
      </Container>
    </Box>
  )
}

export default MapSVG

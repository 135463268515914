import React, { useContext, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"

import GlobalContext from "../contexts/GlobalContext"

import PageWrapper from "../components/PageWrapper"
import { Section, Title, Text } from "../components/Core"
import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"
import MapSVG from "../components/MapSVG"
import bgImg from "../assets/images/bg3.jpg"

const MapPage = ({ data }) => {
  const continent = data.prismicContinent

  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.currentLang !== continent.lang) {
      gContext.setCurrentLang(continent.lang)
    }
  }, [])
  return (
    <>
      <GatsbySeo
        title={continent.data.meta_title?.text || continent.data.title?.text}
        description={continent.data.meta_description?.text}
      />
      <PageWrapper>
        <Hero bgImg={continent.data.cover_image.url || bgImg}>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                {continent.data.title && (
                  <Title variant="hero" color="light">
                    {continent.data.title.text}
                  </Title>
                )}
              </Col>
            </Row>
          </Container>
        </Hero>
        <Section
          pt={["45px !important", null, null, "60px !important"]}
          className="pb-5"
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="9">
                <MapSVG continent={continent} />
              </Col>
              <Col lg="3">
                <ProductAccordion />
                <ProductSlider />
              </Col>
            </Row>
          </Container>
        </Section>
        <div id="support">
          <Support />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapper>
    </>
  )
}
export default MapPage

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismicContinent(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      lang
      data {
        title {
          text
        }
        cover_image {
          url
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`
